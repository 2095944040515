import { useLocalize } from "@component-utils/localization"
import { useErrorToast } from "@component-utils/toasts"
import { nextTick, type Ref } from "vue"
import { renderEditorStyles } from "~/features/editor/styles"
import type { IQuestionnaireStore, SmartStore } from "~/features/QuestionnaireUtils"
import { StoreMode } from "~/stores/utils"

interface SourceContextConfig {
  store: SmartStore<IQuestionnaireStore>
  isContextVisible?: Ref<boolean>
  forceToggle?: boolean
}

const toggleSourceContexts = async (config: SourceContextConfig, citationId?: string) => {
  if (!config.store.state.aiSourceText) return
  
  const editorContainer = (config.isContextVisible 
    ? document.querySelector<HTMLElement>('.avv-editor:not(.hidden)')?.querySelector<HTMLElement>('.avv-container-wrap')
    : document.querySelector<HTMLElement>('.avv-container-wrap'))

  if (!editorContainer) return

  let sourceTextContainer = editorContainer.querySelector<HTMLElement>('.ai-source-text-container')
  let originalContent = editorContainer.querySelector<HTMLElement>('.avv-container')
  const isCurrentlyVisible = sourceTextContainer?.classList.contains('active-source-view')

  if (!config.forceToggle && isCurrentlyVisible && citationId && sourceTextContainer) {
    highlightCitation(citationId, sourceTextContainer)
    return
  }

  if (config.isContextVisible) {
    config.isContextVisible.value = !config.isContextVisible.value
  }
  editorContainer.classList.toggle('ai-source-text-active')

  const titleParagraph = document.querySelector<HTMLElement>('#editor-title')
  const titleSpan = titleParagraph?.querySelector('span')
  const spanContent = titleSpan?.outerHTML ?? ''
  const sourceHtml = config.store.state.aiSourceText.html
  const formats = config.store.state.aiSourceText.formats
  
  const defaultStyleKey = Object.entries(formats).find(entry => entry[1].default)?.[0] ?? 'Normal'
  const fakeStore = {
    hydrated: true,
    hydratedData: true,
    storeMode: StoreMode.NewData,
    docxSettings: {
      formats: formats
    },
    defaultStyle:{
      key: defaultStyleKey,
    }
  }

  if (!sourceTextContainer) {
    sourceTextContainer = document.createElement('div')
    sourceTextContainer.className = 'ai-source-text-container w-full h-full bg-white overflow-y-auto hidden'
    editorContainer.appendChild(sourceTextContainer)
  }

  const shouldShow = !isCurrentlyVisible

  if (shouldShow) {
    const contentContainer = sourceTextContainer.querySelector('.avv-container')
    if (contentContainer) {
      contentContainer.innerHTML = sourceHtml
    } else {
      const newContainer = document.createElement('div')
      newContainer.className = 'avv-container'
      newContainer.innerHTML = sourceHtml
      sourceTextContainer.appendChild(newContainer)
    }
    
    // @ts-expect-error: fake store doesn't fully implement the required interface
    await renderEditorStyles(fakeStore, sourceTextContainer)
    
    if (citationId) {
      highlightCitation(citationId, sourceTextContainer)
    }

    if (titleParagraph) {
      titleParagraph.innerHTML = `${spanContent}${config.store.state.aiSourceText.filename}`
    }
    
    // Show source container and hide original content
    sourceTextContainer.classList.remove('hidden')
    sourceTextContainer.classList.add('active-source-view')
    if (originalContent) {
      originalContent.classList.add('hidden')
    }
  } else {
    // Hide source container and show original content
    sourceTextContainer.classList.add('hidden')
    sourceTextContainer.classList.remove('active-source-view')
    sourceTextContainer.innerHTML = '<div class="avv-container"></div>'
    
    if (originalContent) {
      originalContent.classList.remove('hidden')
    }
    
    if (titleParagraph) {
      titleParagraph.innerHTML = `${spanContent}${config.store.state.documentTitle}`
    }
  }
}

const highlightCitation = (citationId: string, container: HTMLElement) => {
  const localize = useLocalize('draft_assist.citations')
  try {
    const previousHighlights = container.querySelectorAll('.citation-highlighted')
    previousHighlights.forEach(el => {
      el.classList.remove('citation-highlighted', 'bg-primary-250', 'py-1', 'rounded')
    })

    const citationElements = container.querySelectorAll(`[data-citation-id="${citationId}"]`)
    if (citationElements.length === 0) {
      useErrorToast(localize('not_found'))
      return
    }
    
    citationElements.forEach(el => {
      el.classList.add('citation-highlighted', 'bg-primary-250', 'py-1', 'rounded')
    })
    
    void nextTick(() => {
      const firstHighlight = container.querySelector('.citation-highlighted')
      if (firstHighlight) {
        firstHighlight.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        })
      }
    })
  } catch (e) {
    useErrorToast(e)
  }
}

export { toggleSourceContexts }
